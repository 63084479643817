<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template #content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />
      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />
      <AdditionalPayments
        v-show="currentComponent === 'AdditionalPayments'"
        :is-new="isNew"
      />
      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import { FedexDomestic } from '@/constants/Speditors/FedexDomestic/configuration/FedexDomestic';
import ServiceAuthorization from '@/views/Speditors/FedexDomestic/Forms/ServiceAuthorization.vue';
import ContentAndComments from '@/views/Speditors/FedexDomestic/Forms/ContentAndComments.vue';
import AdditionalPayments from '@/views/Speditors/FedexDomestic/Forms/AdditionalPayments.vue';
import LabelSettings from '@/views/Speditors/FedexDomestic/Forms/LabelSettings.vue';

export default {
  name: 'FedexDomestic',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    ContentAndComments,
    AdditionalPayments,
    LabelSettings,
  },
  mixins: [configurationMixin],
  data: () => ({
    currentComponent: 'ServiceAuthorization',
    speditor: 'FedexDomestic',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalPayments', name: 'courierSet.paymentsSettings' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: FedexDomestic });
    },
  },
};
</script>
