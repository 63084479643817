<template>
  <div>
    <form-section :title="$t('courierSet.payment')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.paymentForm"
            :title="$t('courierSet.paymentType')"
            :filed-items="paymentForms"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getConfigurationValues.payerType"
            :title="$t('courierSet.payerType')"
            :filed-items="payerTypes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getDefaultValues.bankAccountNumber"
            :title="$t('courierSet.accountNumber')"
            :rules="getConfigurationValues.paymentForm === 'P' ? 'max:26|required' : 'max:26'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.senderId"
            :title="$t('courierSet.senderId')"
            :rules="getConfigurationValues.paymentForm === 'P' ? 'max:10|required' : 'max:10'"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    paymentForms: [
      { id: 'G', name: 'Gotówka' },
      { id: 'P', name: 'Przelew bankowy' },
    ],
    payerTypes: [
      { id: 1, name: 'Nadawca' },
      { id: 2, name: 'Odbiorca' },
      { id: 3, name: 'Osoba trzecia' },
    ],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
    getDefaultValues() {
      return this.getConfigurationSet().defaultValues;
    },
  },
};
</script>
