import { FedexDefaultValues } from '../../Fedex/configuration/FedexDefaultValues';

export const FedexDomestic = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'fedexdomestic',
  integration: 'FedexDomestic',
  additionalServices: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    services: [],
  },
  accessCode: '',
  url: '',
  paymentForm: '',
  shipmentType: 'K',
  payerType: '',
  senderId: '',
  payerId: '',
  labelFormat: '',
  remarks: '',
  defaultValues: FedexDefaultValues,
};
